module.exports={
  "name": "@vizia/service-tagger",
  "version": "1.0.2",
  "description": "system for accessing individual instances of a service and viewing logs etc",
  "main": "tag.js",
  "scripts": {
    "start": "budo test.js",
    "build": "mkdirp dist && browserify tag.js -s ServiceTagger -g babelify -p tinyify --debug | exorcist dist/tag.min.js.map > dist/tag.min.js",
    "test": "echo \"Error: no test specified\" && exit 1",
    "prepublishOnly": "npm run build"
  },
  "author": "",
  "license": "UNLICENSED",
  "dependencies": {
    "axios": "^0.19.0",
    "currentscript": "^1.1.1",
    "js-cookie": "^2.2.1",
    "platform": "^1.3.5"
  },
  "devDependencies": {
    "@babel/core": "^7.6.4",
    "@babel/preset-env": "^7.6.3",
    "babelify": "^10.0.0",
    "browserify": "^16.5.0",
    "budo": "^11.6.3",
    "exorcist": "^1.0.1",
    "mkdirp": "^0.5.1",
    "sessionstorage": "^0.1.0",
    "tinyify": "^2.5.1"
  }
}
